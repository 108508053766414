import {
  IOngoingOrders,
  IOrderStatusEnum,
  IPendingOrder,
  OrderFlagType,
  TOrderStatus,
} from "src/api/types/orders";
import {
  OrdersDataStoreAction,
  OrdersDataStoreActionKind,
} from "./OrdersDataStore-Actions";

export interface IOrdersDataStoreInitialState {
  orders: IOngoingOrders[];
  pendingOrdersCount: number;
  pendingOrders: IPendingOrder[];
}

export const ordersDataStoreInitialState: IOrdersDataStoreInitialState = {
  orders: [] as IOngoingOrders[],
  pendingOrdersCount: 0,
  pendingOrders: [] as IPendingOrder[],
};

export const ordersDataStoreReducer = (
  state: typeof ordersDataStoreInitialState = ordersDataStoreInitialState,
  action: OrdersDataStoreAction<any>
) => {
  const { type, data } = action;
  const orderList = [...state.orders];
  const pendingOrders = [...state.pendingOrders];

  switch (type) {
    // SET ORDERS
    case OrdersDataStoreActionKind.SET_ORDERS:
      console.log(
        "Pendings On -> SET_ORDERS: ",
        pendingOrders,
        data.pendingCount
      );

      return {
        ...state,
        orders: data.orders,
        pendingOrdersCount: data.pendingCount,
      };
    // ADD_NEW_ORDER
    case OrdersDataStoreActionKind.ADD_NEW_ORDER:
      orderList.unshift(data.newOrder);
      // let { id, applicant, code, cost, date, group, paymentType, reference, vehicleType, hasOtp, otpCode } = data.newOrder;
      // pendingOrders.unshift({ id, applicant, code, cost, date, groupName: group.nameEn, paymentType, reference, vehicleType, hasOtp, otpCode });

      // console.log("Pendings On -> ADD_NEW_ORDER: " , pendingOrders, pendingOrders.length);

      return {
        ...state,
        orders: orderList,
        pendingOrders,
        pendingOrdersCount: pendingOrders.length,
      };
    // ACCEPT_ORDER
    case OrdersDataStoreActionKind.ACCEPT_ORDER:
      const acceptedOrder = orderList.find((order) => order.id === data.id);

      if (acceptedOrder) {
        acceptedOrder.availableActions = data?.availableActions || [];
        acceptedOrder.status = data.status as TOrderStatus;
        acceptedOrder.histories = data.histories;
        acceptedOrder.acceptedRiders = data.acceptedRiders;
        acceptedOrder.flag = OrderFlagType.UPDATED;
      }

      const hasOrderPendingStatus = pendingOrders.find(
        (order) => order.id === data.id
      );

      if (!hasOrderPendingStatus) {
        return {
          ...state,
          orders: orderList,
        };
      } else {
        let filteredPendingOnAcceptOrder = pendingOrders.filter(
          (order) => order.id !== data.id
        );
        console.log(
          "Pendings On -> ACCEPT_ORDER: ",
          filteredPendingOnAcceptOrder,
          filteredPendingOnAcceptOrder.length
        );

        return {
          ...state,
          orders: orderList,
          pendingOrders: filteredPendingOnAcceptOrder,
          pendingOrdersCount: filteredPendingOnAcceptOrder.length,
        };
      }
    // UPDATE_ORDER
    case OrdersDataStoreActionKind.UPDATE_ORDER:
      const updatedOrder = orderList.find((order) => order.id === data.id);

      if (updatedOrder) {
        updatedOrder.availableActions = data?.availableActions || [];
        updatedOrder.status = data.status as TOrderStatus;
        updatedOrder.histories = data.histories;
        updatedOrder.onTheWayDuration = data.onTheWayDuration;
      }

      const isUpdatePendingOrder = pendingOrders.find(
        (order) => order.id === data.id
      );

      if (!isUpdatePendingOrder) {
        return {
          ...state,
          orders: orderList,
        };
      } else {
        let filteredPendingOnUpdateOrder = pendingOrders.filter(
          (order) => order.id !== data.id
        );
        console.log(
          `Pendings On -> UPDATE_ORDER:`,
          filteredPendingOnUpdateOrder,
          filteredPendingOnUpdateOrder.length
        );
        return {
          ...state,
          orders: orderList,
          pendingOrders: filteredPendingOnUpdateOrder,
          pendingOrdersCount: filteredPendingOnUpdateOrder.length,
        };
      }

    // SET_PENDING_ORDERS
    case OrdersDataStoreActionKind.SET_PENDING_ORDERS:
      console.log(
        `Pendings On -> SET_PENDING_ORDERS:`,
        data.orders,
        data.count
      );

      return {
        ...state,
        pendingOrders: data.orders,
        pendingOrdersCount: data.count,
      };
  }
};
