import useSignalR from "src/hooks/useSignalR";
import {
  IOngoingOrders,
  IOrderAccepted,
  IOrderChanged,
  OrderFlagType,
} from "src/api/types/orders";
import { useEffect, useState } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import { WebSocketEvents } from "./websocket";

export function useOngoingTasks() {
  const [newOrder, setNewOrder] = useState<any>({});
  const [acceptedOrder, setAcceptedOrder] = useState<any>({});
  const [changedOrder, setChangedOrder] = useState<any>({});
  const [allowToCallAPI, setAlreadyFetchedAPI] = useState<boolean>(false);

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  const handleMaintenanceRequestCreated = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestCreated,
      (order: string) => {
        const orderJSON: IOngoingOrders = JSON.parse(order);
        orderJSON.flag = OrderFlagType.ADDED;

        setNewOrder(orderJSON);
      }
    );
  };

  const handleDriverAcceptedEvent = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestAcceptedForAdmin,
      (order: string) => {
        const acceptedOrder: IOrderAccepted = JSON.parse(order);

        setAcceptedOrder(acceptedOrder);
      }
    );
  };

  const handleTaskStatusDone = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestDoneForAdmin,
      (order: string) => {
        const changedOrder: IOrderChanged = JSON.parse(order);
        setChangedOrder(changedOrder);
      }
    );
  };

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      setAlreadyFetchedAPI(true);
      return () => {
        setAlreadyFetchedAPI(false);
      };
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connection) {
      handleMaintenanceRequestCreated();
      handleDriverAcceptedEvent();
      handleTaskStatusDone();
    }
  }, [connection]);

  return { newOrder, acceptedOrder, changedOrder, allowToCallAPI };
}
