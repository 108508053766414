import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import Icon from "@mdi/react";
import { mdiInformation } from "@mdi/js";
import React, { SetStateAction, useRef } from "react";
import { toast } from "src/utils/toast";
import { maintenanceDriversService } from "../../../api/services/maintenance/maintenance-drivers";

interface IRiderChangePasswordProps {
  riderId: string;
  openChangePasswordModal: boolean;
  setOpenChangePasswordModal: React.Dispatch<SetStateAction<boolean>>;
}

const MDriverChangePasswordModal: React.FC<IRiderChangePasswordProps> = (
  props
) => {
  const { riderId, openChangePasswordModal, setOpenChangePasswordModal } =
    props;
  const riderPassword = useRef<string>("");

  const handleChangePassword = async () => {
    const newPassword = riderPassword.current.trim();
    if (newPassword === "") {
      toast.error("Password is empty");
      return;
    } else if (newPassword.length < 6) {
      toast.error("Password must be 6 characters at least!");
      return;
    }

    try {
      await maintenanceDriversService.changePassword(riderId, newPassword);
      toast.success("Password changed successfully");
      setOpenChangePasswordModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={openChangePasswordModal}
      onClose={() => setOpenChangePasswordModal(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign="center"
          as="h3"
          fontSize="1.1rem"
          mb="-3"
          fontWeight="500"
        >
          Change Password
        </ModalHeader>
        <ModalBody>
          <FormControl>
            <Input
              type="text"
              placeholder="Enter new Password"
              onChange={(e) => (riderPassword.current = e.target.value)}
            />
            <Text
              fontSize="0.75rem"
              color="#676767"
              mt="2"
              mb="2"
              display="flex"
            >
              <Icon path={mdiInformation} size="18px" />
              <Text as="span" ml="1">
                Password must be 6 characters at least.
              </Text>
            </Text>
          </FormControl>
          <Box display="flex" mt="4" mb="2">
            <Button colorScheme="red" onClick={handleChangePassword}>
              Change
            </Button>
            <Button
              colorScheme="blackAlpha"
              ml="2"
              onClick={() => setOpenChangePasswordModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MDriverChangePasswordModal;
