import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { IManagingZoneRiderItem } from "../../types";
import { DriverItem } from "./DriverItem";

interface IZoneRidersProps {
  items: IManagingZoneRiderItem[];
  onChange: (data: IManagingZoneRiderItem[]) => void;
  searchQuery?: string;
  disabled?: boolean;
  onScrollToEnd?: () => void;
  totalCount: number;
}

const ZoneDrivers: React.FC<IZoneRidersProps> = (props) => {
  const {
    items,
    totalCount,
    onChange,
    searchQuery = "",
    disabled = false,
  } = props;
  const [selectedRiders, setSelectedRiders] = useState<
    IManagingZoneRiderItem[]
  >([]);

  const onRiderChanged = (
    sender: IManagingZoneRiderItem,
    selected: boolean
  ) => {
    if (disabled) {
      return;
    }
    const tempItems = [...items];
    const itemIndex = tempItems.findIndex((ti) => ti.id === sender.id);
    tempItems[itemIndex].selected = selected;

    if (selected) {
      setSelectedRiders((prevSelected) => [
        ...prevSelected,
        tempItems[itemIndex],
      ]);
    } else {
      setSelectedRiders(
        selectedRiders.filter((sr) => sr.id !== tempItems[itemIndex].id)
      );
    }

    onChange(tempItems);
  };

  const selectAll = () => {
    if (disabled) {
      return;
    }
    const tempItems = [...items];
    if (selectedRiders.length === tempItems.length) {
      const deselectedItems = tempItems.map((ti) => ({
        ...ti,
        selected: false,
      }));
      setSelectedRiders([]);
      onChange(deselectedItems);
    } else {
      const tempSelectedItems = tempItems.map((ti) => ({
        ...ti,
        selected: true,
      }));
      setSelectedRiders(tempSelectedItems);
      onChange(tempSelectedItems);
    }
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = e.currentTarget.scrollTop;
    const scrollHeight = e.currentTarget.scrollHeight;

    if (scrollTop + 300 >= scrollHeight && props.onScrollToEnd) {
      props.onScrollToEnd();
    }
  };

  return (
    <Box>
      <div className={"riders-container"} onScroll={onScroll}>
        {items
          .filter(
            (it) =>
              it.name
                .trim()
                .toLowerCase()
                .includes(searchQuery.trim().toLowerCase()) ||
              it.employeeId.includes(searchQuery)
          )
          .map((item, index) => (
            <DriverItem key={index} data={item} onChange={onRiderChanged} />
          ))}
      </div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt="3"
      >
        <Box color="#808080">
          * You can only select 1 item
          {/*{selectedRiders.length > 0*/}
          {/*  ? `${selectedRiders.length}/${totalCount} Selected`*/}
          {/*  : `${totalCount} Drivers`}*/}
        </Box>
        {/*{items.length > 0 && (*/}
        {/*  <Box*/}
        {/*    color="#06A7F7"*/}
        {/*    cursor="pointer"*/}
        {/*    userSelect="none"*/}
        {/*    onClick={selectAll}*/}
        {/*  >*/}
        {/*    {selectedRiders.length < items.length*/}
        {/*      ? "Select All"*/}
        {/*      : "Deselect All"}*/}
        {/*  </Box>*/}
        {/*)}*/}
      </Box>
    </Box>
  );
};

export default ZoneDrivers;
