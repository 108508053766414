import {
  Text,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { panelVehiclesServices } from "src/api/services/vehicle";
import { toast } from "src/utils/toast";

interface IVehicleUnassignModalProps {
  riderId: string;
  openUnassignModal: boolean;
  setIsOpen: (open: boolean) => void;
  handleOnUnassign: (isUnassigned: boolean) => void;
  isMaintenanceDriver?: boolean;
}

const VehicleUnassignModal: React.FC<IVehicleUnassignModalProps> = ({
  riderId,
  isMaintenanceDriver = false,
  openUnassignModal,
  setIsOpen,
  handleOnUnassign,
}) => {
  const { t } = useTranslation();

  const onUnassign = async () => {
    if (riderId) {
      try {
        if (isMaintenanceDriver) {
          await panelVehiclesServices.unassignVehicleMDriver(riderId);
        } else {
          await panelVehiclesServices.unassignVehicle(riderId);
        }
        toast.success(t("messages.vehicleUnassignedSuccessfully"));
        setIsOpen(false);
        handleOnUnassign(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Rider is unknown!");
    }
  };

  return (
    <Modal
      size="2xl"
      isOpen={openUnassignModal}
      onClose={() => setIsOpen(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box mb="4" pt="2">
            <Text as="h3" textAlign="center" fontWeight="500" fontSize="1.2rem">
              Unassign Vehicle
            </Text>
          </Box>
          <Text mt="8" mb="2" ml="10" fontWeight="500">
            Are you sure you want to unassign this vehicle?
          </Text>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="2"
          >
            <Button colorScheme="blackAlpha" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={() => onUnassign()}>
              Confirm
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VehicleUnassignModal;
