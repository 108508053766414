import { Box, Button, IconButton, Input } from "@chakra-ui/react";
import { mdiPlus, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { panelZoneRidersService } from "src/api/services/zone-riders";
import SPSpinner from "src/components/app/SPSpinner";
import { toast } from "src/utils/toast";
import { useManageZoneRidersContext } from "../../ManageZoneDriverProvider";
import { IManagingZoneRiderItem } from "../../types";
import ZoneDrivers from "../ZoneDrivers";
import HorizontalLoading from "src/components/app/HorizontalLoading";

const CurrentDrivers: React.FC = () => {
  const {
    setAddDriversMode,
    zoneRiders,
    setZoneRiders,
    loadingZoneRiders,
    fetchCurrentDrivers,
    totalCount,
    zone,
  } = useManageZoneRidersContext();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [removingItems, setRemovingItems] = useState<boolean>(false);

  const onItemsChanged = (data: IManagingZoneRiderItem[]) => {
    setZoneRiders(data);
  };

  const checkSelectedItemsLength = () => {
    const selectedItems = zoneRiders.filter((r) => r.selected);
    return selectedItems.length > 0;
  };

  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const onRemoveItemsClicked = async () => {
    try {
      const selectedItems = zoneRiders.filter((zr) => zr.selected);
      const ids = selectedItems.map((si) => si.id);
      setRemovingItems(true);
      await panelZoneRidersService.deleteRiders(zone.id, ids);
      const tempItems = zoneRiders.filter((zr) => !ids.includes(zr.id));
      setZoneRiders(tempItems);
      toast.success("Rider(s) Unassigned Successfully");
    } catch (err) {
      console.log(err);
    } finally {
      setRemovingItems(false);
    }
  };

  return (
    <Box
      p="16px"
      cursor={loadingZoneRiders || removingItems ? "wait" : "default"}
    >
      <Box mb="4">
        <Input
          type="search"
          placeholder="Search by name or ID"
          size="lg"
          value={searchQuery}
          onChange={onSearchQueryChanged}
        />
      </Box>
      {loadingZoneRiders && (
        <Box p="4" textAlign="center">
          <HorizontalLoading />
        </Box>
      )}
      <ZoneDrivers
        items={zoneRiders}
        totalCount={totalCount}
        onChange={onItemsChanged}
        searchQuery={searchQuery}
        disabled={loadingZoneRiders || removingItems}
      />
      <Box
        mt="4"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {/*<IconButton*/}
        {/*  aria-label="Delete"*/}
        {/*  icon={<Icon path={mdiTrashCan} size="24px" />}*/}
        {/*  size="lg"*/}
        {/*  colorScheme="primary"*/}
        {/*  disabled={!checkSelectedItemsLength() || removingItems}*/}
        {/*  onClick={onRemoveItemsClicked}*/}
        {/*  isLoading={loadingZoneRiders || removingItems}*/}
        {/*/>*/}
        <Button
          leftIcon={<Icon path={mdiPlus} size="24px" />}
          size="lg"
          colorScheme="secondary"
          color="#333"
          onClick={() => {
            setAddDriversMode(true);
          }}
        >
          {zoneRiders.length > 0 ? "Change Driver" : "Add Driver"}
        </Button>
      </Box>
    </Box>
  );
};

export default CurrentDrivers;
