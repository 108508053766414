import { Box, Button, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";
import {
  mdiAccountCheckOutline,
  mdiAccountMultipleOutline,
  mdiBicycle,
  mdiCar,
  mdiCurrencyUsd,
  mdiPencil,
  mdiWalletOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState, memo } from "react";
import {
  IOngoingOrders,
  IOrderStatusEnum,
  IOrderType,
  OrderPaymentType,
  TOrderStatus,
} from "src/api/types/orders";
import dateHelper from "src/utils/date-helper";
import { VehicleType } from "src/api/types/vehicle";
// import OnTheWayTimer from "../OnTheWayTimer";
import { useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./styles.css";

interface IOngoingOrderItemProps {
  setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
  setIsOpenEligibleRiderModal: (isOpen: boolean) => void;
  orderItem: any;
  setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OngoingOrderItem: React.FC<IOngoingOrderItemProps> = ({ orderItem }) => {
  const [flag, setFlag] = useState<string>("");
  const navigate = useNavigate();
  let timeoutId: any;

  const handleFlagColor = () => {
    setFlag(orderItem?.flag || "");
    timeoutId = setTimeout(() => {
      delete orderItem.flag;
      setFlag("");
    }, 500);
  };

  useEffect(() => {
    handleFlagColor();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleOrderItemClassName = () => {
    return orderItem.status === IOrderStatusEnum.ZoneCanceled
      ? "order_item_row canceled"
      : `${flag} order_item_row`;
  };

  const renderOrderVehicleType = () => {
    return orderItem.vehicleType === VehicleType.Bike ? (
      <Icon
        path={mdiBicycle}
        size="20px"
        style={{ minWidth: "20px", marginRight: "4px" }}
      />
    ) : (
      <Icon
        path={mdiCar}
        size="20px"
        style={{ minWidth: "20px", marginRight: "4px" }}
      />
    );
  };

  const handleOrderValue = () => {
    const icon =
      orderItem.paymentType === OrderPaymentType.Paid ? (
        <Icon
          path={mdiWalletOutline}
          size="20px"
          style={{ marginRight: "4px" }}
        />
      ) : (
        <Icon
          path={mdiCurrencyUsd}
          size="20px"
          style={{ marginRight: "4px" }}
        />
      );

    return (
      <Tooltip label={orderItem.paymentType}>
        <Box display="flex" alignItems="center">
          {icon}
          {orderItem.cost}
        </Box>
      </Tooltip>
    );
  };

  const renderRowLeftBorderClass = () => {
    switch (orderItem.orderType) {
      case IOrderType.ByRider:
        return "orderId_cell by_rider";
      case IOrderType.ByThirdParty:
        return "orderId_cell by_third_party";
      default:
        return "";
    }
  };

  return (
    <Tr
      key={orderItem.id}
      // className={handleOrderItemClassName()}
    >
      <Td>{orderItem.code}</Td>
      <Td>{orderItem.maintenanceDriverEmployeeId}</Td>
      <Td>
        {dateHelper.formatDate(orderItem.createdAt, "DD/MM/YYYY HH:mm") || "-"}
      </Td>
      {/*<Td*/}
      {/*  sx={{ maxWidth: "130px", wordWrap: "break-word" }}*/}
      {/*  className={renderRowLeftBorderClass()}*/}
      {/*>*/}
      {/*  <Box display="flex" alignItems="center">*/}
      {/*    {renderOrderVehicleType()}*/}
      {/*    {orderItem.code}*/}
      {/*  </Box>*/}
      {/*</Td>*/}
      {/*<Td>{orderItem.hasOtp ? orderItem.otpCode : "-"}</Td>*/}
      {/*<Td>{handleOrderValue()}</Td>*/}
      {/*<Td>{orderItem?.group?.nameEn}</Td>*/}
      {/*<Td>{orderItem.applicant}</Td>*/}
      {/*{renderStatusCells(IOrderStatusEnum.Pending)}*/}
      {/*{renderStatusCells(IOrderStatusEnum.RiderAccepted)}*/}
      {/*{renderStatusCells(IOrderStatusEnum.PickedUp)}*/}
      {/*{orderItem.status === IOrderStatusEnum.Delivered*/}
      {/*  ? renderStatusCells(IOrderStatusEnum.Delivered)*/}
      {/*  : renderStatusCells(IOrderStatusEnum.ZoneCanceled)}*/}
      {/*<Td>{renderActionButttons()}</Td>*/}
    </Tr>
  );
};

export default memo(OngoingOrderItem);
