import OrderDataProvider from "src/pages/dashboard/maintenance/tasks/OrdersDataContext";
import { OnGoingOrders } from "src/pages/dashboard/maintenance/tasks/OnGoing";
import { memo } from "react";

const MaintenanceTaskList = memo(() => {
  return (
    <OrderDataProvider>
      <OnGoingOrders />
    </OrderDataProvider>
  );
});

export default MaintenanceTaskList;
