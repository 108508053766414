import OrderDataProvider from "src/pages/dashboard/maintenance/invoices/OrdersDataContext";
import { OnGoingOrders } from "src/pages/dashboard/maintenance/invoices/OnGoing";
import { memo } from "react";

const MaintenanceInvoicesList = memo(() => {
  return (
    <OrderDataProvider>
      <OnGoingOrders />
    </OrderDataProvider>
  );
});

export default MaintenanceInvoicesList;
