import { Box, Button, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";
import {
  mdiAccountCheckOutline,
  mdiAccountMultipleOutline,
  mdiBicycle,
  mdiCar,
  mdiCurrencyUsd,
  mdiPencil,
  mdiWalletOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState, memo } from "react";
import {
  IOngoingOrders,
  IOrderStatusEnum,
  IOrderType,
  OrderPaymentType,
  TOrderStatus,
} from "src/api/types/orders";
import dateHelper from "src/utils/date-helper";
import { VehicleType } from "src/api/types/vehicle";
// import OnTheWayTimer from "../OnTheWayTimer";
import { useNavigate } from "react-router-dom";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import "./styles.css";

interface IOngoingOrderItemProps {
  setIsOpenAcceptedRiderModal: (isOpen: boolean) => void;
  setIsOpenEligibleRiderModal: (isOpen: boolean) => void;
  orderItem: IOngoingOrders;
  setSelectedOrder: (orderItem: IOngoingOrders) => void;
}

const OngoingOrderItem: React.FC<any> = ({
  setIsOpenAcceptedRiderModal,
  setIsOpenEligibleRiderModal,
  setSelectedOrder,
  orderItem,
}) => {
  const [flag, setFlag] = useState<string>("");
  const navigate = useNavigate();
  let timeoutId: any;

  const handleFlagColor = () => {
    setFlag(orderItem?.flag || "");
    timeoutId = setTimeout(() => {
      delete orderItem.flag;
      setFlag("");
    }, 500);
  };

  useEffect(() => {
    handleFlagColor();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const showOnTheWayBriefly = (duration: string) => {
    return duration
      .replace(/hours|hour/, "h")
      .replace(/minutes|minute/, "m")
      .replace(/seconds|second/, "s");
  };

  const handleOrderItemClassName = () => {
    return orderItem.status === IOrderStatusEnum.ZoneCanceled
      ? "order_item_row canceled"
      : `${flag} order_item_row`;
  };

  return (
    <Tr key={orderItem.id} className={handleOrderItemClassName()}>
      <Td>{orderItem.code || "-"}</Td>
      <Td>
        {" "}
        {dateHelper.formatDate(orderItem.date, "DD/MM/YYYY HH:mm") || "-"}
      </Td>
      <Td>{orderItem.riderName || "-"}</Td>
      <Td>{orderItem.driverName || "-"}</Td>
      <Td>{orderItem.contactNumber || "-"}</Td>
      <Td>
        {dateHelper.formatDate(orderItem.acceptedTime, "DD/MM/YYYY HH:mm") ||
          "-"}
      </Td>
      <Td>
        {dateHelper.formatDate(orderItem.completedTime, "DD/MM/YYYY HH:mm") ||
          "-"}
      </Td>
      {/*{renderStatusCells(IOrderStatusEnum.Pending)}*/}
      {/*{renderStatusCells(IOrderStatusEnum.RiderAccepted)}*/}
      {/*{renderStatusCells(IOrderStatusEnum.PickedUp)}*/}
    </Tr>
  );
};

export default memo(OngoingOrderItem);
