import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../../index";
import { IAxiosResponse } from "../../types/axios-response";
import {
  IMaintenanceDriverData,
  MaintenanceDriverSorts,
  MaintenanceDriverListItem,
  IMaintenanceDriverListPayload,
  TUniqueFields,
} from "../../types/maintenanceDrivers";
import { DEFAULT_COUNT } from "../../../constants/api-default-values";

export const maintenanceDriversService = {
  getAll(
    offset: number,
    count: number,
    sort: MaintenanceDriverSorts,
    abortSignal: AbortSignal,
    searchQuery: string = "",
    status: string = "",
    riderId?: MaintenanceDriverListItem["id"]
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
    let searchedStatus = status ? `&WorkingStatusId=${status}` : "";
    let queryRiderId = riderId ? `&AttachRiderId=${riderId}` : "";
    return AxiosInstance.get<IAxiosResponse<IMaintenanceDriverListPayload>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ROOT}?count=${count}&offset=${offset}&sort=${sort}${keyword}${queryRiderId}${searchedStatus}`,
      { signal: abortSignal }
    );
  },
  create(data: IMaintenanceDriverData) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ROOT,
      data
    );
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<MaintenanceDriverListItem>>(
      API_ROUTES.MAINTENANCE_DRIVERS.GET_DETAILS(id),
      { signal: abortSignal }
    );
  },
  update(id: string, data: IMaintenanceDriverData) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.GET_DETAILS(id),
      data
    );
  },
  checkUniqueFieldDublication(value: string, field: TUniqueFields) {
    return AxiosInstance.get<IAxiosResponse<IMaintenanceDriverListPayload>>(
      `${
        API_ROUTES.MAINTENANCE_DRIVERS.ROOT
      }?count=${DEFAULT_COUNT}&${field}=${value.trim().toLowerCase()}`
    );
  },
  changePassword(id: string, rawPassword: string) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.CHANGE_PASSWORD(id),
      { rawPassword: rawPassword }
    );
  },
  toDisabledRider(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.TO_DISABLED_RIDER(id),
      { signal: abortSignal }
    );
  },
  toEnabledRider(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.TO_ENABLED_RIDER(id),
      { signal: abortSignal }
    );
  },
};
