import React, { Dispatch, useReducer } from "react";
import { contextFactory } from "src/utils/context-factory";
import { OrdersDataStoreAction } from "src/pages/dashboard/maintenance/invoices/OrdersDataStore/OrdersDataStore-Actions";
import {
  IOrdersDataStoreInitialState,
  ordersDataStoreInitialState,
  ordersDataStoreReducer,
} from "src/pages/dashboard/maintenance/invoices/OrdersDataStore/OrdersDataStore-Reducer";

export const [orderDataStateContext, useOrderDataState] =
  contextFactory<IOrdersDataStoreInitialState>(
    "orderDataStateContext",
    "useOrderDataState"
  );
export const [orderDataDispatchContext, useOrderDataDispatch] = contextFactory<
  Dispatch<OrdersDataStoreAction<any>>
>("orderDataDispatchContext", "useOrderDataDispatch");

const OrderDataProvider: React.FC = ({ children }) => {
  const [orderDataStates, dispatch] = useReducer(
    ordersDataStoreReducer,
    ordersDataStoreInitialState
  );

  return (
    <orderDataStateContext.Provider value={orderDataStates}>
      <orderDataDispatchContext.Provider value={dispatch}>
        {children}
      </orderDataDispatchContext.Provider>
    </orderDataStateContext.Provider>
  );
};

export default OrderDataProvider;
