import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import OngoingOrderList from "src/pages/dashboard/maintenance/tasks/OngoingOrderList";
import { useOrderDataDispatch, useOrderDataState } from "../OrdersDataContext";
import { OrdersDataStoreActionKind } from "src/pages/dashboard/maintenance/tasks/OrdersDataStore/OrdersDataStore-Actions";
import "./styles.css";
import { useOngoingTasks } from "../useOngoingTasks";

export const OnGoingOrders = memo(() => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.maintenance.task"));

  const dispatch = useOrderDataDispatch();
  const { pendingOrdersCount } = useOrderDataState();
  const { newOrder, acceptedOrder, changedOrder, allowToCallAPI } =
    useOngoingTasks();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (Object.keys(newOrder).length > 0) {
      console.log("New Item---: ", newOrder);
      dispatch({
        type: OrdersDataStoreActionKind.ADD_NEW_ORDER,
        data: { newOrder },
      });
    }
  }, [newOrder]);

  useEffect(() => {
    if (Object.keys(acceptedOrder).length > 0) {
      console.log("Accept Item---: ", acceptedOrder);
      dispatch({
        type: OrdersDataStoreActionKind.ACCEPT_ORDER,
        data: {
          id: acceptedOrder.id,
          date: acceptedOrder.date,
          driverName: acceptedOrder.driverName,
          contactNumber: acceptedOrder.contactNumber,
          acceptedTime: acceptedOrder.acceptedTime,
        },
      });
    }
  }, [acceptedOrder]);

  useEffect(() => {
    if (Object.keys(changedOrder).length > 0) {
      console.log("Done Item ---: ", changedOrder);
      dispatch({
        type: OrdersDataStoreActionKind.UPDATE_ORDER,
        data: {
          id: changedOrder.id,
          date: changedOrder.date,
          driverName: changedOrder.driverName,
          contactNumber: changedOrder.contactNumber,
          completedTime: changedOrder.completedTime,
          acceptedTime: changedOrder.acceptedTime,
        },
      });
    }
  }, [changedOrder]);

  const handlePendingTabStyles = (
    pendingCount: number,
    selectedTab: boolean = false
  ) => {
    let pendingColor = "#000";
    if (pendingCount <= 1) {
      pendingColor = "#00CE52"; // green
    } else if (pendingCount >= 2 && pendingCount <= 4) {
      pendingColor = "#F39905"; // orange
    } else if (pendingCount >= 5) {
      pendingColor = "#C01C23"; //red
    }

    return selectedTab
      ? {
          borderBottom: `2px solid ${pendingColor}`,
          color: pendingColor,
          fontWeight: 600,
        }
      : { color: pendingColor };
  };

  return (
    <Box className="order_main_wrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "60px",
        }}
      >
        <Text as="h3" fontSize="3xl">
          {t("titles.maintenance.task")}
        </Text>
      </Box>
      <Box m={3}>
        <OngoingOrderList
          allowToCallAPI={allowToCallAPI}
          tabIsActive={activeTabIndex === 0 ? true : false}
        />
      </Box>
    </Box>
  );
});
