import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import OngoingOrderList from "src/pages/dashboard/maintenance/invoices/OngoingOrderList";
import { useOrderDataDispatch, useOrderDataState } from "../OrdersDataContext";
import { OrdersDataStoreActionKind } from "src/pages/dashboard/maintenance/invoices/OrdersDataStore/OrdersDataStore-Actions";
import "./styles.css";
import { useOngoingItems } from "../useOngoingItems";

export const OnGoingOrders = memo(() => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.maintenance.invoices"));

  const dispatch = useOrderDataDispatch();
  const { pendingOrdersCount } = useOrderDataState();
  const { newOrder, acceptedOrder, changedOrder, allowToCallAPI } =
    useOngoingItems();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (Object.keys(newOrder).length > 0) {
      console.log("New Item---: ", newOrder);
      dispatch({
        type: OrdersDataStoreActionKind.ADD_NEW_ORDER,
        data: { newOrder },
      });
    }
  }, [newOrder]);

  useEffect(() => {
    if (Object.keys(changedOrder).length > 0) {
      console.log("Changed Item ---: ", changedOrder);
      dispatch({
        type: OrdersDataStoreActionKind.UPDATE_ORDER,
        data: {
          id: changedOrder.id,
          status: changedOrder.status,
          availableActions: changedOrder.availableActions,
          histories: changedOrder.histories,
          onTheWayDuration: changedOrder.onTheWayDuration,
        },
      });
    }
  }, [changedOrder]);

  return (
    <Box className="order_main_wrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "60px",
        }}
      >
        <Text as="h3" fontSize="3xl">
          {t("titles.maintenance.invoices")}
        </Text>
      </Box>
      <Box m={3}>
        <OngoingOrderList
          allowToCallAPI={allowToCallAPI}
          tabIsActive={activeTabIndex === 0 ? true : false}
        />
      </Box>
    </Box>
  );
});
