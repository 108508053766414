import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiArrowLeft } from "@mdi/js";
import { toast } from "src/utils/toast";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  Text,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import HorizontalLoading from "../HorizontalLoading";
import VehicleAssignBody from "./VehicleAssignBody";
import VehicleConfirmationAssign from "./VehicleConfirmationAssign";
import { panelVehiclesServices } from "src/api/services/vehicle";
import { IVehicleListItem } from "src/api/types/vehicle";
import { IRiderListItem } from "src/api/types/riders";
import "./styles.css";
import { MaintenanceDriverListItem } from "src/api/types/maintenanceDrivers";

interface IVehicleAssignProps {
  openAssignmentModal: boolean;
  setIsOpen: (isOpen: boolean) => void;
  rider: IRiderListItem | MaintenanceDriverListItem;
  isMaintenanceDriver?: boolean;
  handleOnAssign: (isAssigned: boolean) => void;
}

const VehicleAssignmentModal: React.FC<IVehicleAssignProps> = ({
  openAssignmentModal,
  setIsOpen,
  rider,
  handleOnAssign,
  isMaintenanceDriver = false,
}) => {
  let riderId = rider.id;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicleListItem>();
  const [isInConfirmState, setIsInConfirmState] = useState<boolean>(false);
  const [disabledAssign, setDisabledAssign] = useState<boolean>(true);

  const onAssign = async (forceAssign: boolean = false) => {
    setDisabledAssign(true);
    setLoading(true);
    try {
      if (selectedVehicle) {
        const result = isMaintenanceDriver
          ? await panelVehiclesServices.assignVehicleMDriver(
              selectedVehicle?.id!,
              {
                maintenanceDriverId: riderId,
                isForcedToAssign: forceAssign,
              }
            )
          : await panelVehiclesServices.assignVehicle(selectedVehicle?.id!, {
              riderId: riderId,
              isForcedToAssign: forceAssign,
            });
        if (result.data.meta.code === 4) {
          setIsInConfirmState(true);
        } else {
          toast.success(t("messages.vehicleAssignedSuccessfully"));
          setIsOpen(false);
          handleOnAssign(true);
        }
      } else {
        toast.error("You must be select a vehicle!");
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setDisabledAssign(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedVehicle) {
      setDisabledAssign(false);
    }
  }, [selectedVehicle]);

  const handleBackToSelectVehicle = () => {
    setIsInConfirmState(false);
    setDisabledAssign(false);
  };

  const showRiderFullName = () => {
    const limitLength = 30;
    const fullNameLength = rider.firstName.length + rider.lastName.length;
    return fullNameLength > limitLength ? (
      (rider.firstName + " " + rider.lastName).substring(0, limitLength - 1) +
        "..."
    ) : (
      <Text as="span" color="#888">
        {rider.firstName + " " + rider.lastName}
      </Text>
    );
  };

  return (
    <Modal
      size="2xl"
      isOpen={openAssignmentModal}
      onClose={() => setIsOpen(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {isInConfirmState && (
            <Box
              as="button"
              pos="absolute"
              left="25"
              top="18"
              onClick={handleBackToSelectVehicle}
            >
              <Icon size="25px" path={mdiArrowLeft} />
            </Box>
          )}
          <Box mb="4" pt="2">
            <Text as="h3" textAlign="center" fontWeight="500" fontSize="1.2rem">
              Assign Vehicle to {showRiderFullName()}
            </Text>
          </Box>
          {isInConfirmState ? (
            <VehicleConfirmationAssign />
          ) : (
            <VehicleAssignBody
              selectedVehicle={selectedVehicle!}
              setSelectedVehicle={setSelectedVehicle}
            />
          )}
          {loading && <HorizontalLoading />}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="2"
          >
            <Button colorScheme="blackAlpha" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            {isInConfirmState ? (
              <Button colorScheme="red" onClick={() => onAssign(true)}>
                Confirm
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                onClick={() => onAssign()}
                disabled={disabledAssign}
              >
                Assign
              </Button>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VehicleAssignmentModal;
