import React from "react";
import { useManageZoneRidersContext } from "../../ManageZoneDriverProvider";
import AddDrivers from "../AddDrivers";
import CurrentDrivers from "../CurrentDrivers";

const ModalContent: React.FC = () => {
  const { addDriversMode } = useManageZoneRidersContext();
  return <div>{addDriversMode ? <AddDrivers /> : <CurrentDrivers />}</div>;
};

export default ModalContent;
