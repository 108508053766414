import { RiderCategory, RiderType } from "src/api/types/rider-type";
import { boolean } from "yup";
import { IPaginatedResult } from "./axios-response";
import { IVehicleListItem } from "./vehicle";
import { IMiniZoneData } from "./zones";

export type TVehicleType = "None" | "Bicycle" | "MotorCycle" | "Car" | "Truck";

export enum MaintenanceDriverSorts {
  None = "None",
  ByNameAsc = "ByNameAsc",
  ByRegisterDateDesc = "ByRegisterDateDesc",
}

export interface MaintenanceDriverBase {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  avatar: string;
  employeeId: string;
  registerDate: string;
  zone: IMiniZoneData;
  vehicle: IVehicleListItem;
  isActive: boolean;
}

export interface MaintenanceDriverListItem extends MaintenanceDriverBase {
  civilIdNo: string;
  driverLicenseNo: string;
  userId: string;
  contactNo: string;
  currentWorkingStatus: IWorkingStatus;
  nextWorkingStatus: IWorkingStatus;
  targetGroup: ITargetGroup;
}

export interface IMaintenanceDriverListPayload
  extends IPaginatedResult<MaintenanceDriverListItem> {}

export interface IMaintenanceDriverData {
  firstName: string;
  lastName: string;
  email?: string;
  civilIdNo: string;
  driverLicenseNo: string;
  contactNo: string;
  rawPassword: string;
  employeeId: string;
  file?: File;
  riderType: RiderType;
  riderCategory: RiderCategory;
  currentWorkingStatusId: number;
  nextWorkingStatusId: number;
  WorkingStatusId: number;
}

export interface IMiniMaintenanceDriverData {
  id: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  avatar: string;
  riderType: RiderType;
}

export type TUniqueFields = "Email" | "CivilIdNo" | "EmployeeId";

export interface IInactiveMaintenanceDriverItem {
  name: string;
  phone: string;
  zone: string;
  checkOutDuration: number;
}

export interface IInactiveMaintenanceDriverData
  extends IPaginatedResult<IInactiveMaintenanceDriverItem> {}

export interface IIdleMaintenanceDriverItem {
  riderId: string;
  name: string;
  phone: string;
  zone: string;
  idleDuration: number;
  workingStatusTitle: string;
  workingStatusId: number;
  isIdle?: boolean;
}

export interface IIdleMaintenanceDriverPayload
  extends IPaginatedResult<IIdleMaintenanceDriverItem> {}

export interface IUnreachableMaintenanceDriverItem {
  riderId: string;
  name: string;
  phone: string;
  zone: string;
  unreachableTime: string;
  isUnreachable?: boolean;
}

export interface MaintenanceDriverWorkingStatusItem {
  riderId: string;
  workingStatusTitle: string;
  workingStatusId: number;
}

export interface IUnreachableMaintenanceDriverPayload
  extends IPaginatedResult<IUnreachableMaintenanceDriverItem> {}

export type IWorkingStatus = {
  id: number;
  title: string;
};

export type ITargetGroup = {
  id: number;
  title: string;
};
