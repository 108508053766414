import React from "react";
import { useManageZoneRidersContext } from "../../ManageZonesContext";
import AddZones from "../AddZones";
import CurrentZones from "../CurrentZones";

const ModalContent: React.FC = () => {
  const { addDriversMode } = useManageZoneRidersContext();
  return <div>{addDriversMode ? <AddZones /> : <CurrentZones />}</div>;
};

export default ModalContent;
