import { mdiArrowLeft, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { useManageZoneRidersContext } from "../../ManageZonesContext";

const ModalHeader: React.FC = () => {
  const { zone, zoneRiders, addDriversMode, setAddDriversMode, closeModal } =
    useManageZoneRidersContext();
  return (
    <div className="m-z-r-header">
      {addDriversMode && (
        <span
          className="back-btn"
          onClick={() => {
            setAddDriversMode(false);
          }}
        >
          <Icon path={mdiArrowLeft} size="24px" />
        </span>
      )}
      <div>
        {addDriversMode
          ? `Add Zones to ${zone.nameEn}`
          : `${zone.nameEn} Zones (${zoneRiders.length})`}
      </div>
      <span className="close-btn" onClick={() => closeModal(true)}>
        <Icon path={mdiClose} size="24px" />
      </span>
    </div>
  );
};

export default ModalHeader;
