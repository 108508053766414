import React, { useEffect, useState } from "react";
import { contextFactory } from "src/utils/context-factory";
import {
  IManageZoneRidersContext,
  IManageZoneRidersModalProps,
  IManagingZoneRiderItem,
} from "./types";
import { panelZoneRidersService } from "src/api/services/zone-riders";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse } from "src/types/general";
import { useAbortController } from "src/hooks/useAbortController";
import { panelZoneAssigneeService } from "../../../../api/services/maintenance/zone-assignee";

export const [ManageZoneRidersContext, useManageZoneRidersContext] =
  contextFactory<IManageZoneRidersContext>(
    "ManageZoneRidersContext",
    "useManageZoneRidersContext"
  );

export const ManageZoneRidersProvider: React.FC<IManageZoneRidersModalProps> = (
  props
) => {
  const { children, zone, closeModal: onClose } = props;
  const [zoneRiders, setZoneRiders] = useState<IManagingZoneRiderItem[]>([]);
  const [addDriversMode, setAddDriversMode] = useState<boolean>(false);

  const { getAbortSignal } = useAbortController();

  const {
    run: fetchCurrentRiders,
    isLoading: loadingZoneRiders,
    data: zoneRidersData,
  } = useAsync<IFetchDataResponse<IManagingZoneRiderItem>, any>((options) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await panelZoneAssigneeService.getZones(
          zone.id,
          getAbortSignal("fetchZoneRiders").signal
        );

        const items: any[] = result.data.data.items.map((it: any) => ({
          id: it.id,
          name: it.nameEn,
          selected: false,
        }));
        setZoneRiders(items);
        resolve({
          ...result.data.data,
          items,
        } as IFetchDataResponse<IManagingZoneRiderItem>);
      } catch (e) {
        reject([]);
      }
    });
  });

  useEffect(() => {
    fetchCurrentRiders({});
  }, []);
  return (
    <ManageZoneRidersContext.Provider
      value={{
        zoneRiders,
        setZoneRiders,
        loadingZoneRiders,
        zone,
        addDriversMode,
        setAddDriversMode,
        closeModal: onClose,
        totalCount: zoneRidersData?.totalCount || 0,
      }}
    >
      <div className="manage-zone-riders">{children}</div>
    </ManageZoneRidersContext.Provider>
  );
};
