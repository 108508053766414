import { Box, List, ListItem, Collapse } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { INavLink } from "../../types";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import "./MenuItem.css";

interface IMenuProps {
  title: string;
  icon?: string;
  showTitle?: boolean;
  navLinks: INavLink[];
}

const MenuItem = ({ navLink }: { navLink: INavLink }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const hasSubItems = navLink.navLinks && navLink.navLinks.length > 0;

  const isSubItemActive =
    hasSubItems &&
    navLink.navLinks?.some((subItem) => location.pathname === subItem.href);

  const [isOpen, setIsOpen] = useState(isSubItemActive);

  useEffect(() => {
    if (isSubItemActive) {
      setIsOpen(true);
    }
  }, [location.pathname, isSubItemActive]);

  return (
    <ListItem mb="2">
      {hasSubItems ? (
        <Box>
          <Box
            className="nav-link ex-item-target"
            onClick={() => setIsOpen(!isOpen)}
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={2}>
              {navLink.icon && (
                <Icon
                  path={navLink.icon}
                  size="24px"
                  className="ex-target-icon"
                />
              )}
              <span className="item-text">{t(navLink.text)}</span>
            </Box>
            <Icon path={isOpen ? mdiChevronUp : mdiChevronDown} size="20px" />
          </Box>
          <Collapse in={isOpen}>
            <List px={8} mt={2}>
              {navLink.navLinks?.map((subItem) => (
                <ListItem className={"sub-item"} key={subItem.href} mb="2">
                  <NavLink
                    to={subItem.href}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link ex-item-target active"
                        : "nav-link ex-item-target not-active"
                    }
                  >
                    {subItem.icon && (
                      <Icon
                        path={subItem.icon}
                        size="24px"
                        className="ex-target-icon"
                      />
                    )}
                    <span className="item-text">{t(subItem.text)}</span>
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      ) : (
        <NavLink
          to={navLink.href}
          className={({ isActive }) =>
            isActive
              ? "nav-link ex-item-target active"
              : "nav-link ex-item-target"
          }
        >
          {navLink.icon && (
            <Icon path={navLink.icon} size="24px" className="ex-target-icon" />
          )}
          <span className="item-text">{t(navLink.text)}</span>
        </NavLink>
      )}
    </ListItem>
  );
};

const Menu: React.FC<IMenuProps> = ({
  title,
  icon,
  navLinks,
  showTitle = true,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="d-category">
      {/*{showTitle && title && (*/}
      {/*  <Box mb="4" display="flex" alignItems="center">*/}
      {/*    {icon && <Icon path={icon} size="24px" />}*/}
      {/*    <Box ml="2" fontWeight="500">*/}
      {/*      {t(title)}*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*)}*/}
      <List className="d-c-menu-items">
        {navLinks.map((nl) => (
          <MenuItem key={nl.href} navLink={nl} />
        ))}
      </List>
    </Box>
  );
};

export default Menu;
