import { Center, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ordersService } from "src/api/services/orders";
import { IOngoingOrders } from "src/api/types/orders";
import { NotFoundResult } from "src/components/app/NotFoundResult";
import {
  DEFAULT_COUNT,
  DEFAULT_OFFSET,
} from "src/constants/api-default-values";
import {
  useOrderDataDispatch,
  useOrderDataState,
} from "src/pages/dashboard/maintenance/invoices/OrdersDataContext";
import { OrdersDataStoreActionKind } from "src/pages/dashboard/maintenance/invoices/OrdersDataStore/OrdersDataStore-Actions";
// import AcceptedRiders from "src/pages/dashboard/maintenance/tasks/AcceptedRiders";
// import EligibleRiders from "src/pages/dashboard/maintenance/tasks/EligibleRiders";
import OngoingOrderItem from "../OngoingOrderItem";
import dateHelper from "src/utils/date-helper";
import SPSpinner from "src/components/app/SPSpinner";
import "./styles.css";
import { maintenanceInvoicesService } from "../../../../../api/services/maintenance/invoices";

interface IOngoingOrderListProps {
  allowToCallAPI: boolean;
  tabIsActive: boolean;
}

const OngoingOrderList: React.FC<IOngoingOrderListProps> = ({
  allowToCallAPI,
  tabIsActive,
}) => {
  const { t } = useTranslation();
  const dispatch = useOrderDataDispatch();
  const { orders } = useOrderDataState();

  const [getAbortController] = useState<AbortController>(new AbortController());
  const [showNoResultFound, setShowNoResultFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true); // we set loading true on initial page for seeing loading state (better UX)
  const [isOpenAcceptedRiderModal, setIsOpenAcceptedRiderModal] =
    useState<boolean>(false);
  const [isOpenEligibleRiderModal, setIsOpenEligibleRiderModal] =
    useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<IOngoingOrders>(
    {} as IOngoingOrders
  );

  const memoizedOrderItems = useMemo(() => {
    let memoizedOrders = orders.map((order: any) => ({
      ...order,
      status: order.status,
    }));
    return memoizedOrders;
  }, [orders]);

  useEffect(() => {
    orders.length > 0 && setShowNoResultFound(false);
  }, [orders]);

  const getOrderList = useCallback(async () => {
    console.log("=== Invoices List API ===");
    // const { start, end } = dateHelper.getFromStartUntilXHoursAgo(new Date(), 2);
    // const fromToRange = dateHelper.getStartEndByDatesAndTimes(start, end);
    try {
      const result = await maintenanceInvoicesService.getList(
        {
          count: DEFAULT_COUNT,
          offset: DEFAULT_OFFSET,
        },
        getAbortController.signal
      );

      dispatch({
        type: OrdersDataStoreActionKind.SET_ORDERS,
        data: {
          orders: result.data.data.items,
          pendingCount: result.data.data.pendingCount,
        },
      });
      result.data.data.items.length > 0
        ? setShowNoResultFound(false)
        : setShowNoResultFound(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getAbortController]);

  useEffect(() => {
    if (allowToCallAPI && tabIsActive) {
      getOrderList();
    }
    // when user change tab
    if (!tabIsActive) {
      console.log("----->", tabIsActive);
      setShowNoResultFound(false);
      setLoading(true);
    }
  }, [getOrderList, allowToCallAPI, tabIsActive]);

  useEffect(() => {
    return () => {
      getAbortController.abort();
    };
  }, []);

  return loading ? (
    <Center minHeight="200px">
      <SPSpinner size="lg" />
    </Center>
  ) : (
    <>
      {/* <TableContainer mt={1}> */}
      {!showNoResultFound ? (
        <Table
          variant="simple"
          colorScheme="blackAlpha"
          size="md"
          boxShadow="md"
          backgroundColor="white"
          borderRadius="md"
          mb={10}
        >
          <Thead bg="#E6E6E6" color="#808080" top="60px" zIndex={2}>
            <Tr>
              <Th>Invoices ID</Th>
              <Th>Driver ID</Th>
              <Th>Created At</Th>
              {/*<Th>code</Th>*/}
              {/*<Th>{t("fields.createdAt")}</Th>*/}
              {/*<Th>{t("fields.orderValue")} (KD)</Th>*/}
            </Tr>
          </Thead>
          <Tbody border="1px solid #F9F9F9">
            {memoizedOrderItems.map((orderItem: any, index) => {
              return (
                <OngoingOrderItem
                  key={index}
                  orderItem={orderItem}
                  setSelectedOrder={setSelectedOrder}
                  setIsOpenAcceptedRiderModal={setIsOpenAcceptedRiderModal}
                  setIsOpenEligibleRiderModal={setIsOpenEligibleRiderModal}
                />
              );
            })}
          </Tbody>
        </Table>
      ) : (
        <NotFoundResult />
      )}
      {/* </TableContainer> */}
      {/* Accepted Rider Modal */}
      {/*<AcceptedRiders*/}
      {/*  orderItem={selectedOrder}*/}
      {/*  isOpen={isOpenAcceptedRiderModal}*/}
      {/*  handleClose={() => setIsOpenAcceptedRiderModal(false)}*/}
      {/*/>*/}
      {/*/!* Eligible RidersSelect Modal *!/*/}
      {/*<EligibleRiders*/}
      {/*  orderItem={selectedOrder}*/}
      {/*  isOpen={isOpenEligibleRiderModal}*/}
      {/*  handleClose={() => setIsOpenEligibleRiderModal(false)}*/}
      {/*/>*/}
    </>
  );
};

export default OngoingOrderList;
