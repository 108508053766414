import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ContentView, {
  ContentViewBody,
  ContentViewHeader,
} from "src/components/app/ContentView";
import { Box, Button, Center, IconButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import FormRow from "src/components/app/FormRow";
import dateHelper from "src/utils/date-helper";
import {
  mdiAccount,
  mdiCar,
  mdiCarOff,
  mdiKeyVariant,
  mdiPencil,
} from "@mdi/js";
import Icon from "@mdi/react";
import ROUTE_CONSTANTS from "src/Routes/route-constants";
import { BrowserBack } from "src/components/app/BrowserBack";
import SPSpinner from "src/components/app/SPSpinner";
import { MaintenanceDriverListItem } from "src/api/types/maintenanceDrivers";
import { IVehicleListItem } from "../../../../../api/types/vehicle";
import VehicleAssignmentModal from "../../../../../components/app/VehicleAssignmentModal";
import VehicleUnassignModal from "../../../../../components/app/VehicleAssignmentModal/VehicleUnassignModal";
import { maintenanceDriversService } from "../../../../../api/services/maintenance/maintenance-drivers";
import MDriverSwitchState from "./components/SwitchState";
import MDriverChangePasswordModal from "src/components/app/MDriverChangePasswordModal";

const MaintenanceDriverDetails: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.maintenanceDrivers"));

  const params = useParams<{ id: string }>();
  const [getDataAbortController] = useState<AbortController>(
    new AbortController()
  );
  const [maintenanceDriversDetails, setMaintenanceDriversDetails] =
    useState<MaintenanceDriverListItem>();
  const [loading, setLoading] = useState<boolean>(true);
  const [openChangePasswordModal, setOpenChangePasswordModal] =
    useState<boolean>(false);
  const [openVehicleAssignmentModal, setOpenVehicleAssignmentModal] =
    useState<boolean>(false);
  const [openVehicleUnassignmentModal, setopenVehicleUnassignmentModal] =
    useState<boolean>(false);
  const [mustBeRefreshComponent, setMustBeRefreshComponent] =
    useState<boolean>(false);
  const [isChangingVehicleState, setIsChangingVehicleState] =
    useState<boolean>(false);
  const [isChangingZoneState, setIsChangingZoneState] =
    useState<boolean>(false);

  const getRiderDetail = useCallback(async () => {
    try {
      const result = await maintenanceDriversService.get(
        params.id!,
        getDataAbortController.signal
      );
      setMaintenanceDriversDetails(result.data.data);
      setTimeout(() => {
        setIsChangingVehicleState(false);
        setIsChangingZoneState(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getDataAbortController, params, mustBeRefreshComponent]);

  useEffect(() => {
    getRiderDetail();
  }, [getRiderDetail]);

  const zoneInfo = () => {
    if (maintenanceDriversDetails?.zone) {
      return (
        <Box>
          <FormRow
            bidirectional
            leftContent={
              <Box mb="2">
                <Text as="span" fontWeight="500">
                  {t("fields.zone")}:{" "}
                </Text>
                {isChangingZoneState ? (
                  <SPSpinner ml="2" />
                ) : (
                  <Text
                    ml="2"
                    color="blue.400"
                    as={Link}
                    to={
                      ROUTE_CONSTANTS.DASHBOARD.ZONES.DETAILS.BY_DATA(
                        maintenanceDriversDetails.zone?.id
                      ).ABSOLUTE
                    }
                  >
                    {maintenanceDriversDetails?.zone?.nameEn || "---"}
                  </Text>
                )}
              </Box>
            }
            rightContent=""
          ></FormRow>
        </Box>
      );
    }
  };

  const renderVehicleSection = (vehicle: IVehicleListItem) => {
    if (isChangingVehicleState) {
      return <SPSpinner />;
    }

    if (vehicle) {
      return (
        <>
          <Text
            as="span"
            ml="2"
            mr="2"
          >{`${vehicle?.plateNumber} - ${vehicle?.chassis}`}</Text>
          <Button
            colorScheme="red"
            onClick={() => setopenVehicleUnassignmentModal(true)}
            disabled={!maintenanceDriversDetails?.isActive}
          >
            <Icon size="22px" path={mdiCarOff} />
            <Text as="span" ml="1">
              Unassign
            </Text>
          </Button>
        </>
      );
    }

    return (
      <Text as="span" ml="2">
        <Button
          bg="#F39905"
          color="#000"
          onClick={() => setOpenVehicleAssignmentModal(true)}
          disabled={!maintenanceDriversDetails?.isActive}
        >
          <Icon size="22px" path={mdiCar} />
          <Text as="span" ml="1">
            Assign
          </Text>
        </Button>
      </Text>
    );
  };

  const onAssignUnassignVehicle = () => {
    setIsChangingVehicleState(true);
    setMustBeRefreshComponent(!mustBeRefreshComponent);
  };

  const handleChangeRiderStatus = () => {
    setIsChangingVehicleState(true);
    setIsChangingZoneState(true);
    setMustBeRefreshComponent(!mustBeRefreshComponent);
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <ContentView>
          {loading && (
            <Center>
              <SPSpinner size="xl" />
            </Center>
          )}
          {maintenanceDriversDetails && (
            <>
              <ContentViewHeader>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Text as="h3" fontSize="1.4em" display="inline-block">
                      Maintenance Driver Details
                    </Text>
                    <IconButton
                      aria-label="Edit rider"
                      as={Link}
                      to={
                        ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.MAINTENANCE_DRIVERS.UPDATE.BY_DATA(
                          maintenanceDriversDetails.id
                        ).ABSOLUTE
                      }
                      ml="4"
                      icon={<Icon path={mdiPencil} size="28px" />}
                      colorScheme="primary"
                      variant="outline"
                      size="sm"
                    />
                    <BrowserBack />
                  </Box>
                  <Button
                    bg="#F39905"
                    color="#000"
                    onClick={() => setOpenChangePasswordModal(true)}
                  >
                    <Icon size="20px" path={mdiKeyVariant} />
                    <Text as="span" ml="1">
                      Change Password
                    </Text>
                  </Button>
                </Box>
              </ContentViewHeader>
              <ContentViewBody>
                <FormRow
                  bidirectional
                  leftContent={
                    maintenanceDriversDetails.avatar ? (
                      <img
                        style={{
                          borderRadius: "50%",
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                        }}
                        src={maintenanceDriversDetails.avatar}
                        alt="rider avatar"
                      />
                    ) : (
                      <Icon
                        path={mdiAccount}
                        size="150px"
                        style={{ opacity: 0.4 }}
                      />
                    )
                  }
                ></FormRow>
                <MDriverSwitchState
                  riderDetail={maintenanceDriversDetails}
                  handleUpdateDetails={handleChangeRiderStatus}
                />
                <Box mb="6">
                  <Box>
                    <Text as="span" fontWeight="500">
                      {t("fields.employeeId")}:{" "}
                    </Text>
                    <Text as="span" ml="2">
                      {maintenanceDriversDetails?.employeeId || "---"}
                    </Text>
                  </Box>
                </Box>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.firstName")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {maintenanceDriversDetails?.firstName || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.lastName")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {maintenanceDriversDetails?.lastName || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.workingStatus")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {maintenanceDriversDetails?.currentWorkingStatus
                          ?.title || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  bidirectional
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        {t("fields.workingStatusNextShift")}:{" "}
                      </Text>
                      <Text as="span" ml="2">
                        {maintenanceDriversDetails?.nextWorkingStatus?.title ||
                          "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                <FormRow
                  leftContent={
                    <Box>
                      <Text as="span" fontWeight="500">
                        Driver's Contact Number:
                      </Text>
                      <Text as="span" ml="2">
                        {maintenanceDriversDetails?.contactNo || "---"}
                      </Text>
                    </Box>
                  }
                  rightContent=""
                ></FormRow>
                {/*<Box mb="6">*/}
                {/*  <Box>*/}
                {/*    <Text as="span" fontWeight="500">*/}
                {/*      {t("fields.driverLicenseNo")}:{" "}*/}
                {/*    </Text>*/}
                {/*    <Text as="span" ml="2">*/}
                {/*      {maintenanceDriversDetails?.driverLicenseNo || "---"}*/}
                {/*    </Text>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                <Box mb="6">
                  <Box>
                    <Text as="span" fontWeight="500">
                      {t("fields.createdAt")}:{" "}
                    </Text>
                    <Text as="span" ml="2">
                      {dateHelper.formatDate(
                        maintenanceDriversDetails.registerDate
                      ) || "---"}
                    </Text>
                  </Box>
                </Box>
                {/*<Box mb="6">*/}
                {/*  <Box display="flex" alignItems="center">*/}
                {/*    <Text as="span" fontWeight="500" mr="2">*/}
                {/*      {t("fields.vehicle")}:{" "}*/}
                {/*    </Text>*/}
                {/*    {renderVehicleSection(maintenanceDriversDetails.vehicle)}*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                {zoneInfo()}
              </ContentViewBody>
            </>
          )}
        </ContentView>
      </Box>
      {openChangePasswordModal && (
        <MDriverChangePasswordModal
          riderId={params.id!}
          openChangePasswordModal={openChangePasswordModal}
          setOpenChangePasswordModal={setOpenChangePasswordModal}
        />
      )}
      {openVehicleAssignmentModal && (
        <VehicleAssignmentModal
          rider={maintenanceDriversDetails!}
          openAssignmentModal={openVehicleAssignmentModal}
          isMaintenanceDriver={true}
          setIsOpen={() => setOpenVehicleAssignmentModal(false)}
          handleOnAssign={onAssignUnassignVehicle}
        />
      )}
      {openVehicleUnassignmentModal && (
        <VehicleUnassignModal
          riderId={maintenanceDriversDetails?.id!}
          openUnassignModal={openVehicleUnassignmentModal}
          isMaintenanceDriver
          setIsOpen={() => setopenVehicleUnassignmentModal(false)}
          handleOnUnassign={onAssignUnassignVehicle}
        />
      )}
    </>
  );
};

export default MaintenanceDriverDetails;
