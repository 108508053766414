import React, { useEffect, useState } from "react";
import { contextFactory } from "src/utils/context-factory";
import {
  IManageZoneRidersContext,
  IManageZoneRidersModalProps,
  IManagingZoneRiderItem,
} from "./types";
import { panelZoneRidersService } from "src/api/services/zone-riders";
import { useAsync } from "src/hooks/useAsync";
import { IFetchDataResponse } from "src/types/general";
import { useAbortController } from "src/hooks/useAbortController";
import { panelZoneDriversService } from "../../../../api/services/maintenance/zone-drivers";

export const [ManageZoneRidersContext, useManageZoneRidersContext] =
  contextFactory<IManageZoneRidersContext>(
    "ManageZoneRidersContext",
    "useManageZoneRidersContext"
  );

export const ManageZoneDriverProvider: React.FC<IManageZoneRidersModalProps> = (
  props
) => {
  const { children, zone, closeModal: onClose } = props;
  const [zoneRiders, setZoneRiders] = useState<IManagingZoneRiderItem[]>([]);
  const [addDriversMode, setAddDriversMode] = useState<boolean>(false);

  const { getAbortSignal } = useAbortController();

  const {
    run: fetchCurrentDrivers,
    isLoading: loadingZoneRiders,
    data: zoneRidersData,
  } = useAsync<IFetchDataResponse<IManagingZoneRiderItem>, any>((options) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await panelZoneDriversService.getDrivers(
          zone.id,
          getAbortSignal("fetchZoneRiders").signal
        );

        const { id, firstName, lastName, employeeId } = result.data.data as any;

        const items: any[] = [
          {
            id,
            name: `${firstName} ${lastName}`,
            employeeId,
          },
        ];
        setZoneRiders(items);
        resolve({
          ...result.data.data,
          items,
        } as IFetchDataResponse<IManagingZoneRiderItem>);
      } catch (e) {
        reject([]);
      }
    });
  });

  useEffect(() => {
    fetchCurrentDrivers({});
  }, []);
  return (
    <ManageZoneRidersContext.Provider
      value={{
        zoneRiders,
        setZoneRiders,
        loadingZoneRiders,
        zone,
        addDriversMode,
        fetchCurrentDrivers,
        setAddDriversMode,
        closeModal: onClose,
        totalCount: zoneRidersData?.totalCount || 0,
      }}
    >
      <div className="manage-zone-riders">{children}</div>
    </ManageZoneRidersContext.Provider>
  );
};
