import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { IManagingZoneRiderItem } from "../../types";
import Checkbox from "../Checkbox";

interface IRiderItemProps {
  data: IManagingZoneRiderItem;
  onChange: (sender: IManagingZoneRiderItem, selected: boolean) => void;
}

export const ZoneItem: React.FC<any> = (props) => {
  const { data, onChange } = props;

  const onItemClicked = () => {
    onChange(data, !data.selected);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      fontWeight="500"
      alignItems="center"
      pt="2"
      pb="2"
      pl="4"
      pr="4"
      cursor="pointer"
      userSelect="none"
      onClick={onItemClicked}
    >
      <Box display="flex" alignItems="center">
        <Checkbox selected={data.selected} />
        <Box>
          <Text as="span" ml="2">
            {data.name}
          </Text>
          {data.nameEn && (
            <Text as="span" color="#9b0909" ml="1">
              (Assigned to {data.nameEn})
            </Text>
          )}
        </Box>
      </Box>
      {/*<Box color="#B3B3B3">ID: {data.employeeId}</Box>*/}
    </Box>
  );
};
