import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "src/api/index";
import { IAxiosResponse } from "src/api/types/axios-response";
import { IRidersListPayload, RiderSorts } from "src/api/types/riders";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { number } from "yup";

export const panelZoneDriversService = {
  getDrivers(zoneId: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.GET_CURRENT_DRIVERS(
        zoneId
      )}?count=${DEFAULT_COUNT}`,
      { signal: abortSignal }
    );
  },
  deleteDrivers(zoneId: string, riderIds: string[]) {
    return AxiosInstance.deleteByBody<IAxiosResponse<{}>>(
      `${API_ROUTES.ZONES.RIDERS(zoneId)}`,
      {
        riderIds,
      }
    );
  },
  addDrivers(zoneId: string, maintenanceDriverId: string[]) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.ASSIGN_DRIVER(zoneId)}`,
      {
        MaintenanceDriverId: maintenanceDriverId[0],
      }
    );
  },
  getNotOwnedDrivers(
    zoneId: string,
    onlyActive: boolean,
    sort: RiderSorts,
    abortSignal: AbortSignal,
    offset: number = 0,
    keyword?: string
  ) {
    let url = `${
      API_ROUTES.MAINTENANCE_DRIVERS.ROOT
    }?count=${50}&onlyActive=${onlyActive}&ExceptZoneId=${zoneId}&sort=${sort}&offset=${offset}`;
    if (keyword) url += `&keyword=${keyword}`;
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(url, {
      signal: abortSignal,
    });
  },
};
