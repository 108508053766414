import React from "react";

import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

import { IManageZoneRidersModalProps } from "./types";
import { ManageZoneDriverProvider } from "./ManageZoneDriverProvider";
import ModalHeader from "./components/ModalHeader";
import ManageRidersContent from "./components/ModalContent";

import "./ManageZoneRiders.css";

const ManageZoneRidersModal: React.FC<
  IManageZoneRidersModalProps & { data: any }
> = (props) => {
  const { closeModal, data } = props;

  const handleCloseModal = () => {
    data?.onClose();
    closeModal(true);
  };

  return (
    <Modal
      isOpen={true}
      size="2xl"
      onClose={handleCloseModal}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ManageZoneDriverProvider {...data} closeModal={handleCloseModal}>
          <ModalHeader />
          <ManageRidersContent />
        </ManageZoneDriverProvider>
      </ModalContent>
    </Modal>
  );
};

export default ManageZoneRidersModal;
