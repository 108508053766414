import { MODAL_TYPES } from "src/types/modals";
import ConfirmModal from "src/components/app/ConfirmModal";
import TableModal from "../TableModal";
import { BulkUpload } from "src/components/app/BulkUpload";
import ManageZoneRidersModal from "../assignee-modal/ManageZoneRidersModal";
import ManageMZoneDriversModal from "../assignee-modal/ManageMZoneDriversModal";
import ManageMZonesModal from "../assignee-modal/ManageMZonesModal";
import { EnterLocationModal } from "src/pages/dashboard/Zones/Create/EnterLocationModal";
import GroupZonesModal from "src/components/app/GroupZonesModal";
import AddNewMessageModal from "src/components/app/AddNewMessageModal";

export const ModalsList = {
  [MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmModal,
  [MODAL_TYPES.TARGET_GROUP_RIDERS]: TableModal,
  [MODAL_TYPES.BULK_UPLOAD_MODAL]: BulkUpload,
  [MODAL_TYPES.MANAGE_ZONE_RIDERS_MODAL]: ManageZoneRidersModal,
  [MODAL_TYPES.MANAGE_M_ZONE_DRIVERS_MODAL]: ManageMZoneDriversModal,
  [MODAL_TYPES.MANAGE_M_ZONES_MODAL]: ManageMZonesModal,
  [MODAL_TYPES.EDIT_ZONE_LOCATION_MODAL]: EnterLocationModal,
  [MODAL_TYPES.GROUP_ZONES_MODAL]: GroupZonesModal,
  [MODAL_TYPES.ADD_NEW_MESSAGE_MODAL]: AddNewMessageModal,
};
