import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse, IPaginatedResult } from "../types/axios-response";
import { IGroupListItem, IZonesGroupData } from "../types/groups";
import { IGetAllType } from "../types/dashboard";
import {
  INewTargetGroup,
  IRiderTargetGroup,
  ITargetGroupListItem,
} from "../types/target-groups";
import { ITargetGroup } from "../types/riders";

export const panelTargetGroupsService = {
  getAll(options: IGetAllType, abortSignal: AbortSignal) {
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<ITargetGroupListItem>>
    >(`${API_ROUTES.TARGET_GROUPS.ROOT}`, {
      signal: abortSignal,
      params: options,
    });
  },
  getActives(options: IGetAllType, abortSignal: AbortSignal) {
    return AxiosInstance.get<ITargetGroupListItem[]>(
      `${API_ROUTES.TARGET_GROUPS.ACTIVES}`,
      {
        signal: abortSignal,
        params: options,
      }
    );
  },
  create(data: INewTargetGroup, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.TARGET_GROUPS.ROOT,
      data,
      { signal: abortSignal }
    );
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<ITargetGroupListItem>>(
      API_ROUTES.TARGET_GROUPS.GET_DETAILS(id),
      { signal: abortSignal }
    );
  },
  update(id: string, data: INewTargetGroup, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.TARGET_GROUPS.UPDATE(id),
      data,
      { signal: abortSignal }
    );
  },
  toggle(riderId: string, action: "enable" | "disable") {
    return AxiosInstance.path<
      IAxiosResponse<IPaginatedResult<IRiderTargetGroup>>
    >(
      action === "enable"
        ? API_ROUTES.TARGET_GROUPS.ENABLE(riderId)
        : API_ROUTES.TARGET_GROUPS.DISABLE(riderId),
      {},
      {
        showError: false,
      }
    );
  },
  getRiderTargetGroups(riderId: string) {
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<IRiderTargetGroup>>
    >(API_ROUTES.TARGET_GROUPS.GET_BY_RIDER_ID(riderId));
  },
  getTargetGroupRiders(targetGroupId: ITargetGroup["id"]) {
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<IRiderTargetGroup>>
    >(API_ROUTES.TARGET_GROUPS.GET_TARGET_GROUP_RIDERS(String(targetGroupId)));
  },
  getMDriverTGroups(riderId: string) {
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<IRiderTargetGroup>>
    >(API_ROUTES.TARGET_GROUPS.GET_BY_MAINTENANCE_DRIVER_ID(riderId));
  },
  getTargetGroupMDrivers(targetGroupId: ITargetGroup["id"]) {
    return AxiosInstance.get<
      IAxiosResponse<IPaginatedResult<IRiderTargetGroup>>
    >(
      API_ROUTES.TARGET_GROUPS.GET_TARGET_GROUP_MAINTENANCE_DRIVERS(
        String(targetGroupId)
      )
    );
  },
};
