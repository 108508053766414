import { Box, Button, Input } from "@chakra-ui/react";
import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { panelZoneRidersService } from "src/api/services/zone-riders";
import { RiderSorts } from "src/api/types/riders";
import { toast } from "src/utils/toast";
import { useManageZoneRidersContext } from "../../ManageZoneRidersContext";
import { IManagingZoneRiderItem } from "../../types";
import ZoneRiders from "../ZoneRiders";
import HorizontalLoading from "../../../../HorizontalLoading";
import { unique } from "../../../../../../utils/iterate";
import { useTyping } from "../../../../../../hooks/useTyping";

const AddRiders: React.FC = () => {
  const { zone, setZoneRiders, zoneRiders, setAddRidersMode } =
    useManageZoneRidersContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [riders, setRiders] = useState<IManagingZoneRiderItem[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fetchRidersController] = useState<AbortController>(
    new AbortController()
  );
  const [addingItems, setAddingItems] = useState<boolean>(false);

  const offset = useRef(0);

  const fetchRiders = useCallback(async (options?: { keyword?: string }) => {
    try {
      setLoading(true);
      const result = await panelZoneRidersService.getNotOwnedRiders(
        zone.id,
        true,
        RiderSorts.ByNameAsc,
        fetchRidersController.signal,
        offset.current,
        options?.keyword
      );
      if (result.data.data.items.length) {
        setTotalCount(result.data.data.totalCount);
        const items: IManagingZoneRiderItem[] = result.data.data.items.map(
          (it) => ({
            id: it.id,
            name: `${it.firstName} ${it.lastName}`,
            selected: false,
            employeeId: it.employeeId,
            zone: it.zone,
          })
        );
        setRiders((prev) => unique([...prev, ...items], "id"));
      }
      offset.current += 50;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { onChange, searchText } = useTyping((keyword) => {
    offset.current = 0;
    fetchRiders({ keyword });
  });

  useEffect(() => {
    fetchRiders();
    return () => {
      fetchRidersController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRiders]);

  const onItemsChanged = (data: IManagingZoneRiderItem[]) => {
    console.log("---onItem");
    setRiders(data);
  };

  const checkSelectedItemsLength = () => {
    const selectedItems = riders.filter((r) => r.selected);
    return selectedItems.length > 0;
  };

  const onAddRidersClicked = async () => {
    try {
      const selectedRiders = riders
        .filter((r) => r.selected)
        .map((item) => ({ ...item, selected: false, zone: undefined }));
      const selectedRidersIds = selectedRiders.map((r) => r.id);
      setAddingItems(true);
      await panelZoneRidersService.addRiders(zone.id, selectedRidersIds);
      setZoneRiders([...zoneRiders, ...selectedRiders]);
      toast.success("Rider(s) Assigned Successfully");
      setAddRidersMode(false);
    } catch (err) {
      console.log(err);
    } finally {
      setAddingItems(false);
    }
  };

  return (
    <Box p="16px" cursor={loading || addingItems ? "wait" : "default"}>
      <Box mb="4">
        <Input
          type="search"
          placeholder="Search by name or ID"
          size="lg"
          value={searchText}
          onChange={onChange}
        />
      </Box>
      {loading && (
        <Box p="4" textAlign="center">
          <HorizontalLoading />
        </Box>
      )}
      <ZoneRiders
        items={riders}
        onChange={onItemsChanged}
        searchQuery={searchText}
        disabled={addingItems}
        onScrollToEnd={fetchRiders}
        totalCount={totalCount}
      />
      <Box mt="4">
        <Button
          leftIcon={<Icon path={mdiCheck} size="24px" />}
          size="lg"
          colorScheme="secondary"
          color="#333"
          disabled={!checkSelectedItemsLength() || addingItems}
          onClick={onAddRidersClicked}
          isLoading={loading || addingItems}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default AddRiders;
