import useSignalR from "src/hooks/useSignalR";
import {
  IOngoingOrders,
  IOrderAccepted,
  IOrderChanged,
  OrderFlagType,
} from "src/api/types/orders";
import { useEffect, useState } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import { WebSocketEvents } from "./websocket";

export function useOngoingItems() {
  const [newOrder, setNewOrder] = useState<any>({});
  const [acceptedOrder, setAcceptedOrder] = useState<any>({});
  const [changedOrder, setChangedOrder] = useState<any>({});
  const [allowToCallAPI, setAlreadyFetchedAPI] = useState<boolean>(false);

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  const handleMaintenanceInvoiceCreated = () => {
    connection?.on(
      WebSocketEvents.CreateMaintenanceInvoice,
      (order: string) => {
        const orderJSON: IOngoingOrders = JSON.parse(order);
        orderJSON.flag = OrderFlagType.ADDED;

        console.log("orderJSON", orderJSON);
        setNewOrder(orderJSON);
      }
    );
  };
  //
  // const handleRiderAcceptedEvent = () => {
  //   connection?.on(WebSocketEvents., (order: string) => {
  //     const acceptedOrder: IOrderAccepted = JSON.parse(order);
  //
  //     setAcceptedOrder(acceptedOrder);
  //   });
  // };
  //
  // const handleOrderStatusChanged = () => {
  //   connection?.on(WebSocketEvents.OrderStatusChanged, (order: string) => {
  //     const changedOrder: IOrderChanged = JSON.parse(order);
  //     setChangedOrder(changedOrder);
  //   });
  // };

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      setAlreadyFetchedAPI(true);
      return () => {
        setAlreadyFetchedAPI(false);
      };
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connection) {
      handleMaintenanceInvoiceCreated();
      // handleRiderAcceptedEvent();
      // handleOrderStatusChanged();
    }
  }, [connection]);

  return { newOrder, acceptedOrder, changedOrder, allowToCallAPI };
}
